import { EventEmitter, Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication-service/authentication.service';
import {
  firstValueFrom,
  Observable,
  of,
  shareReplay,
  Subscription,
} from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DatePipe } from '@angular/common';
import { ScrollPositionService } from '../scroll-position/scroll-position.service';
import { catchError, map, tap } from 'rxjs/operators';
import {
  GoogleAnalyticsService,
  GTMEventChangedProfile,
  GTMEventChangePhoto,
  GTMEventGetProfile,
  GTMEventSetAvailability,
} from '../google-analytics.service';
import {
  AddressModel,
  AppointmentModel,
  AreasServedRateAndConditionsModel,
  CareGiverModel,
  CGTeamsModel,
  NameModel,
  WeeklyAvailabilitiesModel,
  WeeklyAvailabilitiesRestModel,
} from '@nx-c4g/c4g-models';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CaregiverProfileService {
  orderBy = 'Address.City';
  subscription: Subscription;
  profile: CareGiverModel;
  private profiles$: Observable<CareGiverModel[]>;
  public profileUpdated: EventEmitter<CareGiverModel> =
    new EventEmitter<CareGiverModel>();

  documentToDomainObject = (_) => {
    // const object = _.payload.doc.data();
    // object.id = _.payload.doc.id;
    return _;
  };

  constructor(
    private auth: AuthenticationService,
    private gaService: GoogleAnalyticsService,
    private http: HttpClient,
    private router: Router,
    private datePipe: DatePipe,
    private scrollPositionService: ScrollPositionService,
  ) {
    this.auth.initialized.subscribe((v) => {
      this.clearCache();
      console.log('Profile Service: Cache cleared');
    });
  }

  async getCareTeams(): Promise<CGTeamsModel[]> {
    await this.auth.waitForInit();
    const profiles: CGTeamsModel[] = await firstValueFrom(
      this.http.get<CGTeamsModel[]>(environment.api + 'care-giver/cg-teams'),
    );
    return profiles;
  }

  async getProfile(id: string, force = false): Promise<CareGiverModel> {
    await this.auth.waitForInit();
    if (!force && this.profile) {
      console.log('getProfile: return cached profile');
      return this.profile;
    }

    if (force) {
      this.profiles$ = null;
    }

    if (!this.profiles$) {
      this.profiles$ = this.http
        .get<CareGiverModel[]>(environment.api + 'care-giver' + '/' + id)
        .pipe(shareReplay(1));
    }

    try {
      console.log('getProfile: get profile from server');
      const profiles = await firstValueFrom(this.profiles$);

      if (profiles && typeof profiles !== 'undefined' && profiles.length > 0) {
        const cgProfile = profiles[0];
        this.profile = cgProfile;

        return cgProfile;
      } else {
        // this.router.navigateByUrl('/care-givers/me');
        return null;
      }
    } catch (e) {
      // this.router.navigateByUrl('/care-givers/me');
      console.log('error', e);
    }
    return null;
  }

  setAvailability(id: string, appointments: AppointmentModel[]) {
    this.gaService.pushTag(GTMEventSetAvailability);
    this.clearCache();

    return this.http
      .put(environment.api + 'cg-avail', appointments)
      .pipe(catchError(this.handleError<any>('updateHero')));
    // this.firestore.collection('/cg-availability').doc(id)
    //   .set({schedule: this.appointmentHelperService.appointmentsToHoraire(appointments)});
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getAvailability(id: string, from?: Date, to?: Date): Observable<any> {
    const route = from
      ? `${environment.api}cg-avail/${from.toISOString()}/${to.toISOString()}`
      : `${environment.api}cg-avail`;

    return this.http.get(route);
  }

  uploadPhoto(file: File) {
    this.gaService.pushTag(GTMEventChangePhoto);
    this.clearCache();

    const formData = new FormData();
    console.log('Uploading photo', file);
    formData.append('photo', file, file.name);
    return this.http
      .put(`${environment.api}care-giver/photo`, formData)
      .pipe(map((r) => r as string));
  }

  careGiverNameInit(): NameModel {
    return {
      First: '',
      FirstAndLast: '',
      Last: '',
    };
  }

  careGiverAddressInit(): AddressModel {
    return new AddressModel();
  }

  careGiverRateInit(): AreasServedRateAndConditionsModel {
    return {
      CGHourlyRate: 15,
      CGSmokersHouse: '',
      CGTrainedOutOfBed: '',
      CGBio: '',
      ServiceRadius: 25,
      KmMiles: 'Km',
      ZonesYouCannotServe: false,
      ShortestBookingYouAreWillingToAccept: '',
      OtherInterests: '',
      CGSpecialInterests: [],
      CGPetsCannotEnter: [],
    };
  }

  careGiverInit(id: string, email: string): CareGiverModel {
    return new CareGiverModel();
  }

  async saveMyProfile(careGiver: CareGiverModel) {
    this.gaService.pushTag(GTMEventChangedProfile);

    if (careGiver.Address && careGiver.Address.Clean) {
      careGiver.location = {
        type: 'Point',
        coordinates: [
          careGiver.Address.Longitude,
          careGiver.Address.Latitude,
        ],
      };
    }
    await firstValueFrom<CareGiverModel>(
      this.http.put<CareGiverModel>(`${environment.api}care-giver`, careGiver),
    );
    this.profile = await this.getProfile(careGiver._id, true);
    this.clearCache();

    this.profileUpdated.emit(this.profile);
  }

  uploadCGPhoto(image: any, mimeType) {
    this.clearCache();

    return this.http.put(environment.api + 'care-giver/cg-photo', {
      image,
      mimeType,
    });
  }

  async setWeeklyAvailabilities(
    cgId: string,
    weeklyAvailabilities: WeeklyAvailabilitiesModel[],
    weeklyAvailabilitiesTimeZone: any,
  ) {
    const weeklyAvailabilitiesRestModel: WeeklyAvailabilitiesRestModel = {
      weeklyAvailabilities: weeklyAvailabilities,
      weeklyAvailabilitiesTimeZone: weeklyAvailabilitiesTimeZone,
    };
    await firstValueFrom(
      this.http.put(
        environment.api + 'care-giver/weekly-availabilities/' + cgId,
        weeklyAvailabilitiesRestModel,
      ),
    );
    this.clearCache();
    this.profile = await this.getProfile(cgId, true);
    this.profileUpdated.emit(this.profile);
  }

  private clearCache() {
    this.profile = null;
    this.profiles$ = null;
  }

  async deleteProfile(uid) {
    await firstValueFrom(this.http.delete(environment.api + 'care-giver'));
    this.clearCache();
    this.auth.SignOut();
  }
}
